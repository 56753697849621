<script setup lang="ts">
const el1 = ref()
const v1 = useElementVisibility(el1)
</script>

<template>
  <div ref="el1" relative h-496px w-full class="section-3">
    <img
      :class="[v1 && 'animated-fade-in']"
      absolute inset-y-0 h-full object-cover object-right animated animated-delay-100 class="left-[clamp(-10000px,calc((100vw-1920px)+0px)/6,0px)]"
      src="/images/image-174.webp" alt=""
    >

    <div absolute inset-0 container text-center>
      <div absolute bottom-60px row text-start lt-md:z-2 lg:px-7>
        <span text-120px font-bold lh-145px animated :class="[v1 && 'animated-fade-in-up']">1</span>
        <div ml-40px max-w-480px col animated :class="[v1 && 'animated-fade-in-up']" animated-delay-200>
          <span text-53px font-bold lh-64px>
            TOP KPOP STARS
          </span>
          <span mt-14px text-24px font-bold>
            The unparalleled kpop stars will ignite our stage, setting the benchmark for star power.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
// .section-3 {
// }
</style>
