<script setup lang="ts">
</script>

<template>
  <div relative overflow-hidden>
    <img
      class="bg animated animated-slow"
      absolute inset-0 object-cover size-full
      src="/images/hotel-location.webp"
    >

    <div px-30px py-40px>
      <IntroductionMCard
        mx-auto
        h2="GRAND WALKERHILL SEOUL"
        p="177 Walkerhill-ro, Gwangjin-gu, Seoul, Korea 04963"
      >
        <div relative h-560px w-full class="mask" />

        <template #h1>
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="58" viewBox="0 0 36 58" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18 58C18 58 36 27.6173 36 17.7914C36 7.96548 27.9411 0 18 0C8.05887 0 0 7.96548 0 17.7914C0 27.6173 18 58 18 58ZM18 25.2638C22.9706 25.2638 27 21.2811 27 16.3681C27 11.4551 22.9706 7.47239 18 7.47239C13.0294 7.47239 9 11.4551 9 16.3681C9 21.2811 13.0294 25.2638 18 25.2638Z" fill="white" />
          </svg>
        </template>
      </IntroductionMCard>
    </div>
  </div>
</template>

<style scoped lang="scss">
.bg {
  animation: img-scroll ease-in-out 20s alternate infinite;
}
@keyframes img-scroll {
  0% {
    object-position: 0px center;
  }
  100% {
    object-position: 100% center;
  }
}
.mask {
  background: linear-gradient(
    0deg,
    #000000a4 10.58%,
    rgba(0, 0, 0, 0) 41.83%,
    rgba(0, 0, 0, 0) 74.1%,
    #00000096 100%
  );
}
</style>
