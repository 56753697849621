<script setup lang="ts">
const el1 = ref()
const v1 = useElementVisibility(el1)
</script>

<template>
  <div ref="el1" relative min-h-496px w-full class="section-4">
    <div absolute inset-0 bg-black lt-lg:z-1 />

    <div absolute inset-0 overflow-x-hidden size-full lt-lg:z-1>
      <svg
        :class="[v1 && 'animated-fade-in-right']"
        absolute animated class="right-[clamp(-10000px,calc((100vw-1920px)/2+0px),0px)]" xmlns="http://www.w3.org/2000/svg" width="1130" height="496" viewBox="0 0 1130 496" fill="none"
      >
        <path d="M1131.5 496H0.5L343 0H1131.5V496Z" fill="#CE0000" />
      </svg>
      <!--
      <div class="bg-#ff000000" absolute bottom-0 right-0 top-0 /> -->
    </div>

    <div absolute inset-0 z-1 container text-center>
      <img
        :class="[v1 && 'animated-fade-in-left']"
        src="/images/vn-exchange.webp" absolute left-10 h-114px animated animated-delay-200 lg:h-300px md:h-180px
      >

      <img
        :class="[v1 && 'animated-fade-in-right']"
        absolute right-1 top-108px h-80px animated animated-delay-200 lg:top-39px lg:h-110px src="/images/romani.webp"
      >

      <div absolute class="lg:right-2%" bottom-24px row text-start>
        <span
          shrink-0 text-120px font-bold lh-145px
          animated :class="[v1 && 'animated-fade-in-up']"
        >2</span>
        <div :class="[v1 && 'animated-fade-in-up']" ml-40px max-w-480px col break-words animated animated-delay-200 lt-sm:ml-20px>
          <span class="text-[clamp(35px,10vw,53px)] lh-120%" font-bold>
            VISIONARY ORGANIZERS
          </span>
          <span mt-14px text-2xl font-bold>
            VN + Romani Foundation unite, crafting an unforgettable saga of innovation and beauty.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
// .section-4 {
//   .background {
//     background: linear-gradient(180deg, #130404, #2c0005);
//   }
// }
</style>
