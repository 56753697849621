<script setup lang="ts">

</script>

<template>
  <div w-full class="section-2">
    <div container hidden text-center lg:block>
      <h1 class="mt--72.5px" relative z-2 text-100px font-bold lh-145px animated animated-fade-in-up xl:text-120px>
        THE INFLUENCE
      </h1>
      <h2 my-20px text-2xl font-bold animated animated-fade-in-up animated-delay-200>
        MISS W & MCN GLOBAL FESTIVAL: KOREA STATION
      </h2>
      <p mx-auto max-w-900px animated animated-fade-in-up animated-delay-350>
        Miss W Pageant redefines influence for the digital age, merging the decentralized power of Web3 with the cultural force of crypto tokens to spotlight leaders shaping our tomorrow.
      </p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.section-2 {
  background: linear-gradient(180deg, #460008, #000);

  h1 {
    text-transform: uppercase;
  }

  @apply h-140px lg:h-496px;
}
</style>
