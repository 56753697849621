<script setup lang="ts">
const el1 = ref()
const v1 = useElementVisibility(el1)
</script>

<template>
  <div ref="el1" relative min-h-740px w-full lg:min-h-496px class="section-8">
    <img
      :class="[v1 && 'animated-fade-in-left']"
      absolute inset-0 object-cover size-full animated src="/images/rectangle-4222-section-8.webp"
    >

    <div absolute inset-0 z-1 container>
      <div absolute col text-start lg:right-0 lg:row class="bottom-60px <xl:z-2">
        <span text-120px font-bold lh-145px animated :class="[v1 && 'animated-fade-in-up']">300</span>
        <div :class="[v1 && 'animated-fade-in-up']" max-w-480px col animated animated-delay-200 lg:ml-40px>
          <span class="text-[clamp(35px,11vw,53px)] lh-120%" font-bold>
            FINALISTS
          </span>
          <span mt-14px text-2xl font-bold>
            The crème de la crème, invited to showcase their brilliance on this magnificent platform.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
// .section-8 {
//   & > img {
//     object-position: clamp(-10000px, calc(100vw - 2200px) / 2.5, 0px) 0;
//   }
// }
</style>
