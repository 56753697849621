<script setup lang="ts">
const imgs = [
  '/images/image-173-m.webp',
  '/images/image-1473-m.webp',
  '/images/vectorm.webp',
  '/images/image-1472-m.webp',
]
const counter = ref(0)
useIntervalFn(() => {
  counter.value++
}, 3690)
</script>

<template>
  <div px-30px>
    <IntroductionMCard
      mx-auto
      h1="4"
      h2="GLOBAL STOPS"
      p="Journey with us from Seoul to Paris, London, and Singapore - a grand city tour in pursuit of excellence."
    >
      <div relative h-560px>
        <TransitionGroup name="fade-in">
          <template v-for="img, idx in imgs" :key="img">
            <img
              v-if="idx === counter % imgs.length"
              :src="img" :alt="img"
              object-cover size-full
            >
          </template>
        </TransitionGroup>
      </div>
    </IntroductionMCard>
  </div>
</template>

<style scoped lang="scss">
.fade-in-enter-active,
.fade-in-leave-active {
  transition: all 1s ease-out;
  position: absolute;
  inset: 0;
}

.fade-in-enter-from {
  opacity: 0;
  transform: scale(1.1);
}

.fade-in-leave-to {
  opacity: 0;
  transform: scale(1);
}
</style>
