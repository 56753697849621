<script setup lang="ts">

</script>

<template>
  <div class="section-13" pt-40px>
    <IntroductionWhoWillWin type="1" mt-200px />

    <IntroductionSponsors />
  </div>
</template>

<style scoped lang="scss">
</style>
