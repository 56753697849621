<script setup lang="ts">

</script>

<template>
  <div class="section-13" pt-40px>
    <div relative h-290px overflow-hidden>
      <div absolute inset-0 class="bg" />

      <button relative inset-x-0 top-20px class="mx-auto btn w-295px rounded-12px p-[20px_24px] uppercase">
        view contestants
      </button>

      <div absolute inset-x-0 bottom-11.5px px-10px text-center>
        <p text-12px text-white font-bold>
          YOUR VOTE WILL DETERMINE
        </p>
        <p mt-20px text-35px text-white font-bold class="lh-100%">
          WHO WILL WIN
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.bg {
  background-color: #ce0000;
  clip-path: polygon(0% 160px, 160px 0%, 100% 0, 100% 100%, 0 100%);
}
</style>
