<script setup lang="ts">

</script>

<template>
  <div px-30px>
    <IntroductionMCard mx-auto img="/images/image-1475-m1.webp" h1="1" h2="TOP KPOP STARS" p="The unparalleled kpop stars will ignite our stage, setting the benchmark for star power." />
  </div>
</template>

<style scoped lang="scss">
</style>
