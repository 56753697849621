<script setup lang="ts">

</script>

<template>
  <div relative w-full overflow-hidden>
    <img h-640px w-full object-cover src="/images/group-1000002793.webp" class="use-anim">

    <div class="p-[38px_20px_15px_20px]" absolute inset-0 col justify-between text-center lg:hidden>
      <div uppercase>
        <h1 class="lh-100%" text-24px font-bold animated animated-fade-in-down animated-delay-100 animated-slow>
          THE
        </h1>
        <h1 class="lh-100%" mt-4px text-54px font-bold animated animated-fade-in-down animated-slow>
          INFLUENCE
        </h1>
      </div>

      <div mx-auto max-w-320px text-center>
        <h2 text-20px font-bold animated animated-fade-in-down animated-delay-100 animated-slow>
          MISS W & MCN GLOBAL FESTIVAL: KOREA STATION
        </h2>
        <p mt-10px text-10px animated animated-fade-in-down animated-slow>
          Miss W Pageant redefines influence for the digital age, merging the decentralized power of Web3 with the cultural force of crypto tokens to spotlight leaders shaping our tomorrow.
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@keyframes anim {
  from {
    object-position: 0% center;
  }
  50% {
    object-position: 100% center;
  }

  to {
    object-position: 50% center;
  }
}
.use-anim {
  animation: anim 10s forwards ease-in-out;
}
</style>
