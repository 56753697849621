<script setup lang="ts">
const el = ref()
const v = useElementVisibility(el)
</script>

<template>
  <div px-30px>
    <IntroductionMCard
      h1="520M"
      mx-auto
      h2="EYES ON ONE GLOBAL STAGE"
      p="The ultimate testament to our reach and impact, where every glance, every view, contributes to a monumental global experience."
    >
      <div ref="el" relative h-560px>
        <img :class="[v && 'animated-zoom-in']" absolute inset-0 rounded-20px object-cover size-full animated src="/images/rectangle-4222-m.webp">

        <svg absolute left-0 xmlns="http://www.w3.org/2000/svg" width="1028" height="560" viewBox="0 0 1028 560" fill="none">
          <path d="M469 560H-122L318.192 -10H569V560Z" fill="url(#paint0_linear_785_1040)" />
          <defs>
            <linearGradient id="paint0_linear_785_1040" x1="173.5" y1="-120" x2="173.5" y2="560" gradientUnits="userSpaceOnUse">
              <stop offset="0.308668" stop-opacity="0" />
              <stop offset="1" stop-color="#860000" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </IntroductionMCard>
  </div>
</template>

<style scoped lang="scss">
// .section-10 {
// }
</style>
