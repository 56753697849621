<script setup lang="ts">
const el1 = ref()
const v1 = useElementVisibility(el1)
</script>

<template>
  <div ref="el1" relative min-h-506px w-full lg:min-h-496px class="section-5">
    <img
      absolute inset-0 object-cover size-full src="/images/group-10000026971.webp"
      animated :class="[v1 && 'animated-fade-in-left']"
    >

    <div absolute inset-0 z-1 container>
      <div absolute bottom-60px row text-start>
        <span text-120px font-bold lh-145px animated :class="[v1 && 'animated-fade-in-up']">4</span>
        <div :class="[v1 && 'animated-fade-in-up']" ml-40px max-w-480px col animated animated-delay-200 lt-sm:ml-20px>
          <span class="text-[clamp(35px,11vw,53px)] lh-120%" font-bold>
            GLOBAL STOPS
          </span>
          <span mt-14px text-2xl font-bold>
            Journey with us from Seoul to Paris, London, and Singapore - a grand city tour in pursuit of excellence.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
</style>
