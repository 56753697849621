<script setup lang="ts">
</script>

<template>
  <div relative h-640px>
    <img absolute inset-0 object-cover size-full src="/images/image-167-btc-1000.webp" alt="">
    <div class="bg" absolute inset-0 />

    <p class="text-#ce0000 lh-100%" absolute inset-x-0 top-20px text-center text-60px font-bold uppercase>
      1499 BTC
    </p>

    <div absolute inset-x-0 bottom-30px>
      <a href="https://missweb3.app" text-center target="_blank" class="mx-auto btn-primary w-295px rounded-12px p-[20px_24px] uppercase">
        VOTE NOW
      </a>

      <p class="text-#ce0000" mx-auto mt-10px max-w-330px text-center text-14px>
        Crypto Wallet with 1499 BTC will be shown during Event Day.
      </p>
    </div>
  </div>
</template>

<style scoped>
.bg {
  background: linear-gradient(
    0deg,
    #000000 0%,
    rgba(0, 0, 0, 0) 40.4%,
    rgba(0, 0, 0, 0) 60%,
    #000 90%
  );
}
</style>
