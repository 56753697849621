<script setup lang="ts">
const el = ref()
const v = useElementVisibility(el)
</script>

<template>
  <div px-30px>
    <IntroductionMCard
      h1="100"
      mx-auto
      h2="LEADING PARTNERS"
      p="Corporation partners gather, showcasing the pulse of the digital future."
    >
      <div ref="el" relative h-560px>
        <img :class="[v && 'animated-fade-in-down']" absolute inset-0 rounded-30px object-cover size-full animated src="/images/rectangle-42221.webp">
      </div>
    </IntroductionMCard>
  </div>
</template>

<style scoped lang="scss">
</style>
