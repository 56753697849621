<script setup lang="ts">
const el1 = ref()
const v1 = useElementVisibility(el1)
</script>

<template>
  <div ref="el1" relative min-h-550px w-full overflow-hidden lg:min-h-496px class="section-7">
    <img
      :class="[v1 && 'animated-fade-in-down']"
      absolute inset-0 object-cover size-full animated src="/images/rectangle-42221.webp"
    >

    <svg
      :class="[v1 && 'animated-fade-in-left']"
      absolute hidden animated animated-delay-100 lg:block class="left-[clamp(-10000px,calc((100vw-1920px)/2.5+0px),0px)]" xmlns="http://www.w3.org/2000/svg" width="875" height="496" viewBox="0 0 875 496" fill="none"
    >
      <path d="M0 496H874.5L532 0H0V496Z" fill="black" />
    </svg>

    <svg :class="[v1 && 'animated-fade-in-right']" absolute hidden animated animated-delay-200 lg:block class="right-[clamp(-10000px,calc((100vw-1920px)/1.5+0px),0px)]" xmlns="http://www.w3.org/2000/svg" width="873" height="496" viewBox="0 0 873 496" fill="none">
      <path d="M875 0H0.5L343 496H875V0Z" fill="black" />
    </svg>

    <svg absolute lg:hidden class="left-[clamp(-10000px,calc((100vw-1920px)/2.7+0px),0px)]" xmlns="http://www.w3.org/2000/svg" width="875" height="550" viewBox="0 0 875 550" fill="none">
      <path d="M0 550H874.5L532 0H0V550Z" fill="black" />
    </svg>

    <div absolute inset-0 z-1 container>
      <div class="bottom-60px" absolute col text-start lg:row>
        <span text-120px font-bold lh-145px animated :class="[v1 && 'animated-fade-in-up']">100</span>
        <div :class="[v1 && 'animated-fade-in-up']" max-w-480px col animated animated-delay-200 lg:ml-40px>
          <span class="text-[clamp(35px,11vw,53px)] lh-120%" font-bold>
            LEADING PARTNERS
          </span>
          <span mt-14px text-2xl font-bold>
            Corporation partners gather, showcasing the pulse of the digital future.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.section-7 {
  & > img {
    object-position: clamp(-10000px, calc(100vw - 2200px) / 2.5, 0px) 0;
  }
}
</style>
