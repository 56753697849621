<script setup lang="ts">
const el1 = ref()
const v1 = useElementVisibility(el1)
</script>

<template>
  <div ref="el1" relative min-h-603px w-full lg:min-h-496px class="section-10">
    <img absolute inset-0 object-cover size-full src="/images/rectangle-4222.webp" animated :class="[v1 && 'animated-fade-in-right']">
    <svg :class="[v1 && 'animated-fade-in-left']" absolute animated animated-delay-200 class="left-[clamp(-10000px,calc((100vw-1920px)/2.5+0px),0px)]" xmlns="http://www.w3.org/2000/svg" width="1131" height="496" viewBox="0 0 1131 496" fill="none">
      <path d="M0 0H1131L788.5 496H0V0Z" fill="#CE0000" />
    </svg>

    <div absolute inset-0 z-1 container>
      <div class="lg:ml-2.5%" col text-start lg:mt-115px lg:row>
        <span text-120px font-bold lh-145px animated :class="[v1 && 'animated-fade-in-up']">520M</span>
        <div :class="[v1 && 'animated-fade-in-up']" max-w-480px col animated animated-delay-200 lg:ml-40px>
          <span class="text-[clamp(35px,11vw,53px)] lh-120%" font-bold>
            EYES ON ONE GLOBAL STAGE
          </span>
          <span mt-14px text-2xl font-bold>
            The ultimate testament to our reach and impact, where every glance, every view, contributes to a monumental global experience.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
// .section-10 {
// }
</style>
