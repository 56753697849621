<script setup lang="ts">
const target = ref(null)
const targetIsVisible = useElementVisibility(target)
</script>

<template>
  <div ref="target" class="section-11" relative h-100vw max-h-924px min-h-530px w-full overflow-hidden>
    <img :class="[targetIsVisible && 'animated animated-slowest animated-scale-in']" absolute inset-0 object-cover size-full src="/images/hotel-location.webp">

    <div absolute inset-0 z-1 container>
      <div class="lg:ml-3%" mt-200px row text-start md:mt-283px>
        <svg :class="[targetIsVisible && 'animated-fade-in-up']" xmlns="http://www.w3.org/2000/svg" width="59" height="96" viewBox="0 0 59 96" fill="none" shrink-0 animated>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M29.5 96C29.5 96 59 45.7115 59 29.4479C59 13.1842 45.7924 0 29.5 0C13.2076 0 0 13.1842 0 29.4479C0 45.7115 29.5 96 29.5 96ZM29.5 41.816C37.6462 41.816 44.25 35.2238 44.25 27.092C44.25 18.9602 37.6462 12.3681 29.5 12.3681C21.3538 12.3681 14.75 18.9602 14.75 27.092C14.75 35.2238 21.3538 41.816 29.5 41.816Z" fill="white" />
        </svg>
        <div :class="[targetIsVisible && 'animated-fade-in-up']" ml-40px max-w-480px col animated animated-delay-200 lt-sm:ml-20px>
          <span class="text-[clamp(35px,11vw,53px)] lh-120%" font-bold>
            GRAND WALKERHILL SEOUL
          </span>
          <span mt-14px text-2xl font-bold>
            177 Walkerhill-ro, Gwangjin-gu, Seoul, Korea 04963
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
// .section-11 {
// }
.animated-scale-in {
  animation-name: scale-in;
}
</style>
