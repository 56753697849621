<script setup lang="ts">
const el1 = ref()
const v1 = useElementVisibility(el1)
</script>

<template>
  <div ref="el1" relative min-h-566px w-full overflow-hidden lg:min-h-496px class="section-6">
    <img :class="[v1 && 'animated-fade-in-down']" absolute inset-0 object-cover size-full animated src="/images/group-10000027431-m.webp">

    <svg
      :class="[v1 && 'animated-fade-in-left']"
      absolute animated animated-delay-100 class="left-[clamp(-10000px,calc((100vw-1920px)/2.5+0px),0px)]" xmlns="http://www.w3.org/2000/svg" width="530" height="496" viewBox="0 0 530 496" fill="none"
    >
      <path d="M0 496H529.5L187 0H0V496Z" fill="#CE0000" />
    </svg>
    <svg
      :class="[v1 && 'animated-fade-in-right']"
      absolute animated animated-delay-200 class="right-[clamp(-10000px,calc((100vw-1920px)/1.5+0px),0px)]" xmlns="http://www.w3.org/2000/svg" width="1218" height="496" viewBox="0 0 1218 496" fill="none"
    >
      <path d="M1220 0H0L342.5 496H1220V0Z" fill="#CE0000" />
    </svg>

    <div absolute inset-0 z-1 container>
      <div class="md:right-3% lg:ml-3%" absolute bottom-60px col text-start lg:row>
        <span text-120px font-bold lh-145px animated :class="[v1 && 'animated-fade-in-up']">20</span>
        <div :class="[v1 && 'animated-fade-in-up']" max-w-480px col animated animated-delay-200 lg:ml-40px>
          <span class="text-[clamp(35px,11vw,53px)] lh-120%" font-bold>
            DAZZLING STARS
          </span>
          <span mt-14px text-2xl font-bold>
            Witness a spectacular medley by 20 illustrious artists, harmonizing the world of music and beauty.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
// .section-6 {
//   .background {
//     background: linear-gradient(180deg, #130404, #2c0005);
//   }
// }
</style>
