<script setup lang="ts">
const a1 = ref()
const v1 = useElementVisibility(a1)
</script>

<template>
  <div px-30px>
    <IntroductionMCard
      mx-auto
      h1="2"
      h2="VISIONARY ORGANIZERS"
      p="VN + Romani Foundation unite, crafting an unforgettable saga of innovation and beauty."
    >
      <div ref="a1" relative h-560px>
        <div absolute inset-0 bg-black lt-lg:z-1 />
        <svg absolute z-1 animated :class="[v1 && 'animated-fade-in-right']" class="inset-y-0 left--30" xmlns="http://www.w3.org/2000/svg" width="699" height="560" viewBox="0 0 699 560" fill="none">
          <path d="M1131.5 560H0.5L363 0H1131.5V560Z" fill="#CE0000" />
        </svg>

        <img :class="[v1 && 'animated-fade-in-left']" src="/images/vn-exchange.webp" absolute left-14px z-2 h-114px animated>

        <img :class="[v1 && 'animated-fade-in-down']" absolute right-14px top-126px z-2 h-64px animated src="/images/romani.webp">
      </div>
    </IntroductionMCard>
  </div>
</template>

<style scoped lang="scss">
// .section-4 {
//   .background {
//     background: linear-gradient(180deg, #130404, #2c0005);
//   }
// }
</style>
