<script setup lang="ts">
const el = ref()
const v = useElementVisibility(el)
</script>

<template>
  <div px-30px>
    <IntroductionMCard
      h1="2000"
      mx-auto
      h2="SPECTATORS"
      p="Be part of an exclusive audience, witnessing history unfold at the prestigious Grand Walkerhill Seoul."
    >
      <div ref="el" relative h-560px>
        <img :class="[v && 'animated-fade-in-down']" absolute inset-0 object-cover size-full animated src="/images/rectangle-4222-section-9.webp">
      </div>
    </IntroductionMCard>
  </div>
</template>

<style scoped lang="scss">
// .section-9 {
// }
</style>
