<script setup lang="ts">
</script>

<template>
  <div relative overflow-hidden>
    <img class="bg" src="/images/image-1475-m.webp" absolute inset-0 object-cover size-full>

    <div px-30px py-40px>
      <IntroductionMCard
        mx-auto
        h1="300"
        h2="FINALISTS"
        p="The crème de la crème, invited to showcase their brilliance on this magnificent platform."
      >
        <div relative h-560px>
          <img class="bg-2" absolute inset-0 rounded-30px object-cover size-full src="/images/image-1475-ml.webp">
        </div>
      </IntroductionMCard>
    </div>
  </div>
</template>

<style scoped lang="scss">
@keyframes img-scroll {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.3);
  }
}
img.bg {
  animation: img-scroll ease-in-out 8s 0.5s alternate infinite;
  object-fit: cover;
}

img.bg-2 {
  animation: img-scroll ease-in-out 8s alternate infinite;
}
</style>
