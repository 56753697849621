<script setup lang="ts">

</script>

<template>
  <div relative w-full overflow-hidden>
    <img src="/images/group-1000002793.webp" h-46vw max-h-956px min-h-393px w-full object-cover animated animated-slow style="animation-name: scale-in">
  </div>
</template>

<style scoped>

</style>
