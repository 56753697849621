<script setup lang="ts">
defineProps({
  h1: null,
  h2: null,
  p: null,
  img: null
})

const el = ref()
const v = useElementVisibility(el)

const el2 = ref()
const v2 = useElementVisibility(el2)
</script>

<template>
  <div relative h-560px max-w-500px overflow-hidden rounded-40px>
    <slot>
      <div ref="el2" overflow-hidden>
        <img :src="img" alt="" rounded-40px object-cover size-full animated :class="[v2 && 'animated-fade-in-up']">
      </div>
    </slot>

    <div ref="el" absolute bottom-30px left-14px right-14px z-10>
      <div :class="[v && 'animated-zoom-in']" origin-left-bottom animated>
        <slot name="h1">
          <h1 text-54px font-bold class="lh-100%">
            {{ h1 }}
          </h1>
        </slot>
      </div>
      <h2 my-10px text-20px font-bold animated animated-delay-150 :class="[v && 'animated-fade-in-up']">
        {{ h2 }}
      </h2>
      <p text-14px animated animated-delay-325 :class="[v && 'animated-fade-in-up']">
        {{ p }}
      </p>
    </div>
  </div>
</template>

<style scoped>

</style>
