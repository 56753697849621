<script setup lang="ts">
const el1 = ref()
const v1 = useElementVisibility(el1)
</script>

<template>
  <div ref="el1" relative h-100vw max-h-792px min-h-531px w-full class="section-12">
    <img absolute inset-0 object-cover size-full src="/images/contestants.webp">

    <div absolute inset-0 z-1 container>
      <div :class="[v1 && 'animated-fade-in-left']" class="left-3% text-[clamp(45px,11vw,120px)] lh-120%" absolute bottom-20px max-w-911px row text-start font-bold animated>
        MEET<br>
        THE<br>
        CONTESTANTS
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.section-12 {
  & > img {
    animation: img-scroll ease-in-out 12s alternate infinite;
    object-fit: cover;
    @keyframes img-scroll {
      from {
        object-position: 0px 0px;
      }
      to {
        object-position: 100% 0px;
      }
    }
  }
}
</style>
