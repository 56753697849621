<script setup lang="ts">
const el1 = ref()
const v1 = useElementVisibility(el1)
</script>

<template>
  <div ref="el1" relative min-h-603px w-full overflow-hidden lg:min-h-496px class="section-9">
    <img
      absolute inset-0 object-cover size-full src="/images/rectangle-4222-section-9.webp"
      animated :class="[v1 && 'animated-fade-in-down']"
    >
    <svg :class="[v1 && 'animated-fade-in-left']" absolute animated animated-delay-200 class="left-[clamp(-10000px,calc((100vw-1920px)/2.5+0px),0px)]" xmlns="http://www.w3.org/2000/svg" width="774" height="496" viewBox="0 0 774 496" fill="none">
      <path d="M0 0H774L431.5 496H0V0Z" fill="black" />
    </svg>
    <svg :class="[v1 && 'animated-fade-in-right']" absolute animated animated-delay-200 class="right-[clamp(-10000px,calc((100vw-1920px)/1.5+0px),0px)]" xmlns="http://www.w3.org/2000/svg" width="796" height="496" viewBox="0 0 796 496" fill="none">
      <path d="M796 496H0.5L343 0H796V496Z" fill="black" />
    </svg>

    <div absolute inset-0 z-1 container>
      <div class="lg:left-2.5%" absolute bottom-60px col text-start lg:row>
        <span text-120px font-bold lh-145px animated :class="[v1 && 'animated-fade-in-up']">2000</span>
        <div :class="[v1 && 'animated-fade-in-up']" max-w-480px col animated animated-delay-200 lg:ml-40px>
          <span class="text-[clamp(35px,11vw,53px)] lh-120%" font-bold>
            SPECTATORS
          </span>
          <span mt-14px text-2xl font-bold>
            Be part of an exclusive audience, witnessing history unfold at the prestigious Grand Walkerhill Seoul.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
// .section-9 {
// }
</style>
