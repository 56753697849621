<script setup lang="ts">

</script>

<template>
  <div px-30px>
    <IntroductionMCard
      mx-auto
      h1="20"
      h2="DAZZLING STARS"
      p="Witness a spectacular medley by 20 illustrious artists, harmonizing the world of music and beauty."
    >
      <div relative h-560px>
        <img class="bg" absolute inset-0 object-cover size-full src="/images/group-10000027431-m.webp">
      </div>
    </IntroductionMCard>
  </div>
</template>

<style scoped lang="scss">
img.bg {
  animation: img-scroll ease-in-out 12s alternate infinite;
  object-fit: cover;
  @keyframes img-scroll {
    from {
      object-position: 0px 0px;
    }
    to {
      object-position: 100% 0px;
    }
  }
}
</style>
